import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { notification } from "antd";
import { mask, unMask } from "../utils/mask";
import api from "../service/api";
import { cleanObject } from "../utils/cleanObject";
const TrackingFormContext = createContext({})

function TrackingFormProvider({ children }) {
  const [hash, setHash] = useState(null)

  const [apiNotification, contextHolder] = notification.useNotification()

  const [invoiceInfo, setInvoiceInfo] = useState({
    number: '',
    series: '',
    orderNumber: '',
  })
  const [sender, setSender] = useState({ name: '', document: '', isValid: false });
  const [recipient, setRecipient] = useState({ name: '', document: '', isValid: false });
  const [lastModificationTarget, setLastModificationTarget] = useState(null)
  const [lastDocumentSentToApi, setLastDocumentSentToApi] = useState({ name: '', document: '', target: '' })
  const [disabledByDocument, setDisabledByDocument] = useState(false)

  const [transporterData, setTransporterData] = useState(null)
  const [isLoadingTransporter, setIsLoadingTransporter] = useState(false)
  const [isLoadingTransporterError, setIsLoadingTransporterError] = useState(false)


  const [isValidatingDocument, setIsValidatingDocument] = useState(false)

  const [loadingInvoices, setLoadingInvoices] = useState(false)
  const [invoices, setInvoices] = useState(null)

  const handleInvoiceChange = useCallback((ev, propKey) => {
    const { value } = ev.target

    setInvoiceInfo((prev) => {
      return { ...prev, [propKey]: value.trim() }
    })
  }, [setInvoiceInfo])

  const getTransporterData = useCallback(async (hash) => {
    setIsLoadingTransporterError(false)
    setIsLoadingTransporter(true);
    setHash(hash)

    try {
      const { data } = await api.get(`/v2/logistica/rastreamento/transportadora/${hash}/dados`);
      setTransporterData(data.body)
    } catch (errorRequest) {
      setIsLoadingTransporterError(true)
    } finally {
      setIsLoadingTransporter(false);
    }
  }, [])

  const getInvoices = useCallback(async (hash) => {
    try {
      const paramsRequest = {
        invoiceNumber: invoiceInfo.number,
        invoiceSeries: invoiceInfo.series,
        orderNumber: invoiceInfo.orderNumber,
        senderDocument: sender.document,
        recipientDocument: recipient.document,
      }

      const cleanedParams = cleanObject(paramsRequest)

      setLoadingInvoices(true);
      const { data } = await api.get(`/v2/logistica/rastreamento/transportadora/${hash}/buscar-nota-fiscal`, {
        params: cleanedParams
      });

      setInvoices(data.body)
    } catch (errorRequest) {
      if (!errorRequest.response) {
        apiNotification.error({
          message: `Erro`,
          description: "Não conseguimos nos conectar ao servidor. Tente novamente mais tarde",
          placement: 'topRight',
          duration: 5,
        });
        return;
      }

      const { response } = errorRequest;
      apiNotification.error({
        message: `Erro`,
        description: response.data.message,
        placement: 'topRight',
        duration: 5,
      });

      setInvoices([])
    } finally {
      setLoadingInvoices(false);
    }
  }, [apiNotification, invoiceInfo.number, invoiceInfo.orderNumber, invoiceInfo.series, recipient.document, sender.document])

  const handleDocumentChange = useCallback(async (value, target, hash) => {
    const maskPattern = (value) => unMask(value).length === 11
      ? ['999.999.999-99']
      : ['SS.SSS.SSS/SSSS-99']

    const newValue = {
      name: '',
      document: mask(value, maskPattern(value)),
      isValid: false
    }

    newValue.document === '' ? setDisabledByDocument(false) : setDisabledByDocument(true)

    setLastModificationTarget(target)
    target === 'recipient'
      ? setRecipient(newValue)
      : setSender(newValue)
  }, [])

  const validateDocument = useCallback(async (target, data, hash) => {
    try {
      setIsValidatingDocument(true)
      setLastDocumentSentToApi({ name: data.name, document: data.document, target })
      const response = await api.get(`/v2/logistica/rastreamento/transportadora/${hash}/buscar-cliremet-documento?document=${data.document}`);

      if (response.data.statusCode === 200) {
        const validatedDocument = response.data
        target === 'recipient'
          ? setRecipient({
            name: validatedDocument.body.nome,
            document: validatedDocument.body.cnpj,
            isValid: true
          })
          : setSender({
            name: validatedDocument.body.nome,
            document: validatedDocument.body.cnpj,
            isValid: true
          })

        setDisabledByDocument(false)

        return
      }
    } catch (err) {
      apiNotification.error({
        message: `Erro`,
        description: err.response.data.message || "Algo deu errado!",
        placement: 'topRight',
        duration: 5,
      });
    } finally {
      setIsValidatingDocument(false)
    }
  }, [apiNotification])

  useEffect(() => {
    const timeOut = setTimeout(async () => {
      let lastModifiedField = lastModificationTarget === 'sender' ? sender : recipient


      if (lastModifiedField?.document?.length !== 14 && lastModifiedField?.document?.length !== 18) return
      if (lastModifiedField.isValid) return
      if (lastDocumentSentToApi.document === lastModifiedField.document && lastDocumentSentToApi.target === lastModificationTarget) {
        lastDocumentSentToApi.target === 'recipient'
          ? setRecipient(lastModifiedField)
          : setSender(lastModifiedField)
        return
      }

      await validateDocument(lastModificationTarget, lastModifiedField, hash)
    }, 500)

    return () => {
      clearTimeout(timeOut)
    }
  }, [sender, recipient, lastModificationTarget, hash, apiNotification, validateDocument, lastDocumentSentToApi])

  const handleDocumentChangeFromModal = useCallback((selected, modalMode) => {
    if (modalMode === "sender") {
      setSender({
        name: selected.nome,
        document: selected.cnpj,
        isValid: true
      })
    } else {
      setRecipient({
        name: selected.nome,
        document: selected.cnpj,
        isValid: true
      })
    }
  }, [])

  const clearForm = useCallback(() => {
    setInvoiceInfo({
      number: '',
      series: '',
      orderNumber: '',
    })
    setSender('')
    setRecipient('')
    setLoadingInvoices(null)
    setIsValidatingDocument(false)
    setInvoices(null)
    setDisabledByDocument(false)
  }, [])

  const value = useMemo(() => ({
    invoiceInfo,
    sender,
    recipient,
    invoices,
    loadingInvoices,
    isValidatingDocument,
    disabledByDocument,
    transporterData,
    isLoadingTransporter,
    isLoadingTransporterError,
    getTransporterData,
    getInvoices,
    handleInvoiceChange,
    handleDocumentChange,
    handleDocumentChangeFromModal,
    clearForm,
  }), [
    clearForm, 
    disabledByDocument, 
    getInvoices, 
    handleDocumentChange, 
    handleDocumentChangeFromModal,
    handleInvoiceChange, 
    invoiceInfo, 
    invoices,
    transporterData,
    isLoadingTransporter,
    isLoadingTransporterError,
    getTransporterData,
    isValidatingDocument, 
    loadingInvoices, 
    recipient, 
    sender
  ])

  return <TrackingFormContext.Provider value={value}>
    {contextHolder}
    {children}
  </TrackingFormContext.Provider>
}

function useTrackingFormContext(hash) {
  const context = useContext(TrackingFormContext)
  return context
}

export { TrackingFormProvider, useTrackingFormContext }

