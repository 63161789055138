import React from 'react';

import { Container } from './styles';
import { Table } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { formatDateBrazil } from '../../../../../utils/format-date-brazil';
import { useTrackingFormContext } from '../../../../../Context/TrackingFormContext';

function DataTable() {
  const { hash } = useParams()
  const { invoices } = useTrackingFormContext()

  return <Container>
    <Table
      bordered
      columns={
        [
          {
            title: "Data de Emissão", dataIndex: "data_emissao", key: "data_emissao", width: 175,
            render: (_, record) => {
              return formatDateBrazil(record.data_emissao, 'dd/MM/yyyy')}
          },
          { title: "Nº da Nota Fiscal", dataIndex: "numero_nota", key: "numero_nota", width: 150 },
          { title: "Série", dataIndex: "serie_nota", key: "serie_nota", width: 75 },
          { title: "Nº do pedido", dataIndex: "nro_pedido", key: "nro_pedido", width: 120, },
          {
            title: "Remetente", dataIndex: "nome_remetente", key: "sender", width: 450,
            render: (_, record) => (
              <>{record.nome_fantasia_remetente}</>
            )
          },
          {
            title: "Destinatário", dataIndex: "nome_destinatario", key: "recipient", width: 450,
            render: (_, record) => (
              <>{record.nome_fantasia_destinatario}</>
            )
          },
          {
            title: "Ações", key: "action", fixed: 'right', width: 115, render: (_, record) => (
              <Link
                to={{ pathname: `/logistica/rastreamento-transportadora/${hash}/detalhes/${record.hash}` }}
              >Ver detalhes</Link>
            )
          }
        ]
      }
      dataSource={invoices}
      rowKey={record => 
        `${record.number}-${record.series}-${record.senderDocument}-${record.recipientDocument}`
      }
      size="large"
      scroll={{
        x: '100%',
      }}
      pagination={{ pageSize: 50, size: "small" }}
    />
  </Container>;
}

export default DataTable;