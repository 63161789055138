import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div `
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  display: flex;
  flex-direction: column;
` 